import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Link, useSearchParams } from 'react-router-dom';

import { ArrowForward } from '@mui/icons-material';
import {
  Box,
  Button,
  Collapse,
  Grid,
  IconButton,
  Pagination,
  Paper,
  Stack,
  Typography,
} from '@mui/material';
import { format } from 'date-fns';

import NotificationNavbarFilter from '../../../../components/filters/notification-navbar-filter';
import Content from '../../../../components/layouts/content';
import Loading from '../../../../components/loading';
import { Slot } from '../../../../contexts/slot';
import resources from '../../../../resources';
import { INotification } from '../../../../resources/notification';
import { IPagination } from '../../../../resources/pagination';

const Header: React.FC = () => (
  <Grid
    container
    spacing={3}
  >
    <Grid
      item
      md={5}
      xs={12}
    >
      <Typography
        sx={{ display: 'block' }}
        variant="caption"
      >
        Mensagem
      </Typography>
    </Grid>
    <Grid
      item
      md={2}
      xs={12}
    >
      <Typography
        sx={{ display: 'block' }}
        variant="caption"
      >
        Autor
      </Typography>
    </Grid>
    <Grid
      item
      md={2}
      xs={12}
    >
      <Typography
        sx={{ display: 'block' }}
        variant="caption"
      >
        Data
      </Typography>
    </Grid>
    <Grid
      item
      md={1}
      xs={12}
    >
      <Typography
        sx={{ display: 'block' }}
        variant="caption"
      >
        Enviados
      </Typography>
    </Grid>
    <Grid
      item
      md={1}
      xs={12}
    >
      <Typography
        sx={{ display: 'block' }}
        variant="caption"
      >
        Lidos
      </Typography>
    </Grid>
    <Grid
      alignSelf="center"
      item
      md="auto"
      xs={12}
    />
  </Grid>
);

const Notification: React.FC<Partial<INotification>> = ({
  id,
  author,
  title,
  createdAt,
  description,
  quantitySend,
  quantityRead,
}) => {
  const [searchParams] = useSearchParams();

  const link = useMemo(() => {
    if (Array.from(searchParams.keys()).length > 0) {
      return `./${id}?${searchParams.toString()}`;
    }
    return `./${id}`;
  }, [id, searchParams]);

  return (
    <Paper sx={{ p: 3, fontSize: 13, color: 'grey.600' }}>
      <Grid
        alignItems="center"
        container
      >
        <Grid
          item
          md={5}
          xs={12}
        >
          <Typography
            sx={{ display: { xs: 'block', md: 'none' } }}
            variant="caption"
          >
            {title}
          </Typography>
          <Stack>
            <Typography sx={{ fontWeight: 'bold' }}>{title}</Typography>
            <Typography>{description}</Typography>
          </Stack>
        </Grid>
        <Grid
          item
          md={2}
          xs={12}
        >
          <Typography
            sx={{ display: { xs: 'block', md: 'none' } }}
            variant="caption"
          >
            Autor
          </Typography>
          <strong>{author}</strong>
        </Grid>
        <Grid
          item
          md={2}
          xs={12}
        >
          <Typography
            sx={{ display: { xs: 'block', md: 'none' } }}
            variant="caption"
          >
            Data
          </Typography>
          {createdAt ? format(new Date(createdAt), 'dd/MM/yyyy HH:mm') : null}
        </Grid>
        <Grid
          item
          md={1}
          xs={12}
        >
          <Typography
            sx={{ display: { xs: 'block', md: 'none' } }}
            variant="caption"
          >
            Enviados
          </Typography>
          <strong>{quantitySend}</strong>
        </Grid>
        <Grid
          item
          md={1}
          sx={{ color: 'success.main' }}
          xs={12}
        >
          <Typography
            sx={{ display: { xs: 'block', md: 'none' } }}
            variant="caption"
          >
            Lidos
          </Typography>
          <strong>{quantityRead}</strong>
        </Grid>
        <Grid
          alignSelf="center"
          item
          md="auto"
          xs={12}
        >
          <IconButton
            component={Link}
            to={link}
          >
            <ArrowForward />
          </IconButton>
        </Grid>
      </Grid>
    </Paper>
  );
};

const NotificationList: React.FC = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [loadingNotifications, setLoadingNotifications] = useState(false);
  const [notifications, setNotifications] = useState<INotification[]>([]);
  const [meta, setMeta] = useState<IPagination<INotification>['meta']>({
    limit: 0,
    page: 0,
    pages: 0,
    records: 0,
  });

  const fetchNotifications = useCallback(async () => {
    let centers = searchParams.has('centers')
      ? Array.from(searchParams.getAll('centers'))
      : null;
    if (!centers) {
      const response = await resources.use('property').getCenters();
      centers = response.payload?.map((center) => center.codCenter) ?? [];
    }

    const response = await resources.use('notification').getAll({
      ...(searchParams.has('page') ? { page: +searchParams.get('page')! } : {}),
      ...(searchParams.has('toDate')
        ? { toDate: searchParams.get('toDate')! }
        : {}),
      ...(searchParams.has('fromDate')
        ? { fromDate: searchParams.get('fromDate')! }
        : {}),
      codCenters: centers,
    });

    if (response.status === 'OK' && response.payload) {
      setNotifications(response.payload.data);
      setMeta(response.payload.meta!);
    }
  }, [searchParams]);

  useEffect(() => {
    setLoadingNotifications(true);
    fetchNotifications().finally(() => {
      setLoadingNotifications(false);
    });
  }, [fetchNotifications]);

  return (
    // params
    <>
      <Slot name="navbar">
        <NotificationNavbarFilter />
      </Slot>
      <Content
        header={
          <Button
            component={Link}
            to="./create"
            variant="contained"
          >
            Nova Notificação
          </Button>
        }
        title="Central de notificações"
      >
        <Stack
          direction="column"
          spacing={1}
        >
          <Collapse in={!loadingNotifications && notifications.length > 0}>
            <Box
              px={3}
              sx={{ display: { xs: 'none', md: 'block' } }}
            >
              <Header />
            </Box>
          </Collapse>
          <Loading loading={loadingNotifications} />
          {notifications.length === 0 && !loadingNotifications && (
            <Paper sx={{ p: 3 }}>
              <Typography variant="caption">
                Nenhuma notificação disponível
              </Typography>
            </Paper>
          )}
          {notifications.map((notification, key) => (
            <Notification
              author={notification.author}
              createdAt={notification.createdAt}
              description={notification.description}
              id={notification.id}
              key={key}
              quantityRead={notification.quantityRead}
              quantitySend={notification.quantitySend}
              title={notification.title}
            />
          ))}
          {meta.pages > 1 && (
            <Stack
              direction="row"
              justifyContent="flex-end"
              pt={4}
            >
              <Pagination
                count={meta.pages}
                onChange={(_, page) => {
                  if (page > 1) {
                    searchParams.set('page', `${page}`);
                  } else {
                    searchParams.delete('page');
                  }
                  setSearchParams(searchParams);
                }}
                page={
                  searchParams.has('page')
                    ? Number(searchParams.get('page'))
                    : 1
                }
                sx={{ justifySelf: 'center' }}
              />
            </Stack>
          )}
        </Stack>
      </Content>
    </>
  );
};
export default NotificationList;
