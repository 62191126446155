import React, { useCallback, useMemo, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import {
  ArrowDropDown,
  CalendarMonth,
  FilterList,
  Flag,
  Label,
  Refresh,
} from '@mui/icons-material';
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  Stack,
  SvgIcon,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { format, parse } from 'date-fns';
import { isEmpty } from 'lodash';
import {
  bindDialog,
  bindTrigger,
  usePopupState,
} from 'material-ui-popup-state/hooks';

import CenterFilter from '../center-filter';
import DatePeriodFilter, {
  ApplyValues,
  IDatePeriodFilter,
} from '../date-period-filter';
import TagsFilter from '../tag-filter';

interface IContentNavbarFilter {
  periodFilterProps?: IDatePeriodFilter;
}
type TagValues = {
  tags: string[];
};

type CenterValues = {
  centers: string[];
};

type TempSearchParams = ApplyValues & TagValues & CenterValues;

const ContentNavbarFilter: React.FC<IContentNavbarFilter> = ({
  periodFilterProps,
}) => {
  const [searchParams, setSearchParams] = useSearchParams();

  const [changeFilter, setChangeFilter] = useState(false);
  const [tempSearchParams, setTempSearchParams] = useState<
    Partial<TempSearchParams>
  >(() => ({
    fromDate: searchParams.has('fromDate')
      ? searchParams.get('fromDate')
      : null,
    toDate: searchParams.has('toDate') ? searchParams.get('toDate') : null,
    tags: searchParams.getAll('tags'),
    centers: searchParams.has('centers') ? searchParams.getAll('centers') : [],
  }));

  const period = useMemo(() => {
    const { fromDate } = tempSearchParams;
    const { toDate } = tempSearchParams;
    let label = 'Período';
    if (fromDate && toDate) {
      label = `${format(
        parse(fromDate, 'yyyy-MM-dd', new Date()),
        'dd/MM/yyyy'
      )} à ${format(parse(toDate, 'yyyy-MM-dd', new Date()), 'dd/MM/yyyy')}`;
    }

    if (fromDate && !toDate) {
      label = `À partir de ${format(
        parse(fromDate, 'yyyy-MM-dd', new Date()),
        'dd/MM/yyyy'
      )}`;
    }

    if (toDate && !fromDate) {
      label = `Até ${format(
        parse(toDate, 'yyyy-MM-dd', new Date()),
        'dd/MM/yyyy'
      )}`;
    }

    return {
      label,
      fromDate,
      toDate,
    };
  }, [tempSearchParams]);

  const tag = useMemo(() => {
    const { tags } = tempSearchParams;
    let label = 'Tags';

    if (tags && tags.length > 0) {
      label = `${tags.slice(0, 3).join(', ')}${
        tags.length > 3 ? `, +${tags.length - 3}` : ''
      }`;
    }
    return {
      label,
      tags,
    };
  }, [tempSearchParams]);

  const center = useMemo(() => {
    const { centers } = tempSearchParams;
    let label = 'Centros';

    if (centers && centers.length > 0) {
      label = `${centers.slice(0, 3).join(', ')}${
        centers.length > 3 ? `, +${centers.length - 3}` : ''
      }`;
    }
    return {
      label,
      centers,
    };
  }, [tempSearchParams]);

  const setCenters = useCallback((centers: string[]) => {
    setTempSearchParams((old) => {
      const newParams = { ...old };
      newParams.centers = centers;
      return newParams;
    });
    setChangeFilter(true);
  }, []);

  const setPeriod = useCallback((range: ApplyValues) => {
    setTempSearchParams((old) => {
      const newParams = { ...old };
      newParams.fromDate = range.fromDate;
      newParams.toDate = range.toDate;
      return newParams;
    });
    setChangeFilter(true);
  }, []);

  const setTags = useCallback((tags: string[]) => {
    setTempSearchParams((old) => {
      const newParams = { ...old };
      newParams.tags = tags;
      return newParams;
    });
    setChangeFilter(true);
  }, []);

  const apply = useCallback(() => {
    const newParams = Object.entries(tempSearchParams);
    if (newParams.length > 0) {
      newParams.forEach(([key, value]) => {
        if (value && !isEmpty(value)) {
          if (Array.isArray(value)) {
            searchParams.delete(key);
            value.forEach((v) => {
              searchParams.append(key, v);
            });
          } else {
            searchParams.set(key, value);
          }
        } else {
          searchParams.delete(key);
        }
      });
      setSearchParams(searchParams);
      setChangeFilter(false);
    }
  }, [tempSearchParams, searchParams, setSearchParams]);

  const filtersComponent = useMemo(
    () => (
      <Stack
        direction={{ xs: 'column', md: 'row' }}
        divider={
          <Divider
            flexItem
            orientation="vertical"
          />
        }
        spacing={{ xs: 0, md: 2 }}
        sx={{
          px: { md: 3 },
          flexGrow: 1,
          justifyContent: 'space-around',
        }}
      >
        {/* <MenuDropdown
          icon={<Flag />}
          label="Planta"
        />
        <MenuDropdown
          icon={<BusinessCenter />}
          label="Supervisor"
        /> */}
        <CenterFilter
          button={
            <Button
              color="tertiary"
              endIcon={
                <SvgIcon>
                  <ArrowDropDown />
                </SvgIcon>
              }
              startIcon={<Flag />}
              variant="text"
            >
              {center.label}
            </Button>
          }
          defaultValue={center.centers}
          onChange={setCenters}
        />
        <TagsFilter
          button={
            <Button
              color="tertiary"
              endIcon={
                <SvgIcon>
                  <ArrowDropDown />
                </SvgIcon>
              }
              startIcon={<Label />}
              variant="text"
            >
              {tag.label}
            </Button>
          }
          defaultValue={tag.tags}
          onChange={setTags}
        />
        <DatePeriodFilter
          button={
            <Button
              color="tertiary"
              endIcon={<ArrowDropDown />}
              startIcon={<CalendarMonth />}
              variant="text"
            >
              {period.label}
            </Button>
          }
          defaultValue={{
            fromDate: period.fromDate ? new Date(period.fromDate) : null,
            toDate: period.toDate ? new Date(period.toDate) : null,
          }}
          onApply={setPeriod}
          {...periodFilterProps}
        />
        <Button
          disabled={!changeFilter}
          onClick={apply}
          startIcon={<Refresh />}
        >
          Aplicar
        </Button>
      </Stack>
    ),
    [
      setPeriod,
      apply,
      periodFilterProps,
      period,
      changeFilter,
      tag,
      setTags,
      center.centers,
      center.label,
      setCenters,
    ]
  );

  const theme = useTheme();
  const onlyMobile = useMediaQuery(theme.breakpoints.down('md'));
  const popupState = usePopupState({
    popupId: 'filter-dialog',
    variant: 'dialog',
  });

  if (onlyMobile)
    return (
      <>
        <IconButton
          sx={{ order: 4 }}
          {...bindTrigger(popupState)}
        >
          <FilterList />
        </IconButton>
        <Dialog {...bindDialog(popupState)}>
          <DialogTitle>Filtros</DialogTitle>
          <DialogContent>{filtersComponent}</DialogContent>
        </Dialog>
      </>
    );

  return filtersComponent;
};
export default ContentNavbarFilter;
