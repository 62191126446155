import React, {
  cloneElement,
  ReactNode,
  useCallback,
  useMemo,
  useState,
} from 'react';

import {
  Button,
  Divider,
  FormControlLabel,
  Popover,
  Radio,
  RadioGroup,
  Stack,
  TextField,
} from '@mui/material';
import {
  CalendarPickerView,
  LocalizationProvider,
  StaticDatePicker,
} from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { format, isAfter, startOfYear, subDays, subHours } from 'date-fns';
import ptBrLocale from 'date-fns/locale/pt-BR';
import {
  bindPopover,
  bindTrigger,
  usePopupState,
} from 'material-ui-popup-state/hooks';

export interface ApplyValues {
  toDate: string | null;
  fromDate: string | null;
}

export interface ISuggestion {
  [path: string]: IDateRange & { label: string };
}
export interface IDatePeriodFilter {
  button: ReactNode;
  defaultValue?: IDateRange;
  format?: string;
  onApply?(value: ApplyValues): void;
  calendarViews?: CalendarPickerView[];
  calendarOpenTo?: CalendarPickerView;
  fromDateRequired?: boolean;
  toDateRequired?: boolean;
  suggestions?: ISuggestion;
  setDefaultValueOnReset?: IDateRange;
}

export interface IDateRange {
  fromDate: Date | null;
  toDate: Date | null;
}

const PRESET: ISuggestion = {
  last24h: {
    label: 'Últimas 24h',
    fromDate: subHours(new Date(), 24),
    toDate: new Date(),
  },
  last7days: {
    label: 'Últimos 7 dias',
    fromDate: subDays(new Date(), 7),
    toDate: new Date(),
  },
  last30days: {
    label: 'Últimas 30 dias',
    fromDate: subDays(new Date(), 30),
    toDate: new Date(),
  },
  thisYear: {
    label: 'Este ano',
    fromDate: startOfYear(new Date()),
    toDate: new Date(),
  },
};

const DatePeriodFilter: React.FC<IDatePeriodFilter> = ({
  button,
  defaultValue,
  onApply,
  format: dateFormat = 'yyyy-MM-dd',
  calendarViews,
  calendarOpenTo = 'day',
  fromDateRequired = false,
  toDateRequired = false,
  setDefaultValueOnReset,
  suggestions = PRESET,
}) => {
  const [value, setValue] = useState<IDateRange>(() => ({
    fromDate: null,
    toDate: null,
    ...defaultValue,
  }));

  const popupState = usePopupState({
    variant: 'popover',
    popupId: 'period-popover',
  });

  const validateApply = useMemo(() => {
    if (fromDateRequired && !value.fromDate) return false;
    if (toDateRequired && !value.toDate) return false;
    return true;
  }, [fromDateRequired, toDateRequired, value.fromDate, value.toDate]);

  const currentPresetSelected = useMemo(() => {
    const preset = Object.entries(suggestions)
      .map(([name, range]) => ({
        name,
        range,
      }))
      .find(
        ({ range }) =>
          range.fromDate &&
          value.fromDate &&
          range.toDate &&
          value.toDate &&
          format(range.fromDate, 'yyyy-MM-dd') ===
            format(value.fromDate, 'yyyy-MM-dd') &&
          format(range.toDate, 'yyyy-MM-dd') ===
            format(value.toDate, 'yyyy-MM-dd')
      );

    return preset?.name;
  }, [suggestions, value.fromDate, value.toDate]);

  const setFromDate = useCallback(
    (date: Date | null) => {
      setValue((old) => {
        const newValue = old;
        if (date && newValue.toDate && isAfter(date, newValue.toDate)) {
          newValue.toDate = date;
        }
        if (!date && setDefaultValueOnReset?.fromDate) {
          newValue.fromDate = setDefaultValueOnReset?.fromDate;
        } else {
          newValue.fromDate = date;
        }
        return { ...old };
      });
    },
    [setDefaultValueOnReset]
  );

  const setToDate = useCallback(
    (date: Date | null) => {
      setValue((old) => {
        const newValue = old;
        if (date && newValue.fromDate && isAfter(newValue.fromDate, date)) {
          newValue.fromDate = date;
        }

        if (!date && setDefaultValueOnReset?.toDate) {
          newValue.toDate = setDefaultValueOnReset?.toDate;
        } else {
          newValue.toDate = date;
        }
        return { ...old };
      });
    },
    [setDefaultValueOnReset]
  );

  const setRangeDate = useCallback(
    (fromDate: Date | null, toDate: Date | null) => {
      setFromDate(fromDate);
      setToDate(toDate);
    },
    [setFromDate, setToDate]
  );

  const setDateByPreset = useCallback(
    (_: any, presetName: string) => {
      const preset = suggestions[presetName];
      if (preset) {
        setRangeDate(preset.fromDate, preset.toDate);
      } else {
        throw new Error('preset not found');
      }
    },
    [setRangeDate, suggestions]
  );

  const onApplyHandler = useCallback(() => {
    if (onApply) {
      // if (setDefaultValueOnReset) {
      //   console.log('aqui');

      //   onApply({
      //     fromDate:
      //       !value.fromDate && defaultValue?.fromDate
      //         ? format(defaultValue?.fromDate, dateFormat)
      //         : value.fromDate
      //         ? format(value.fromDate, dateFormat)
      //         : null,
      //     toDate:
      //       !value.toDate && defaultValue?.toDate
      //         ? format(defaultValue?.toDate, dateFormat)
      //         : value.toDate
      //         ? format(value.toDate, dateFormat)
      //         : null,
      //   });
      // } else {
      onApply({
        fromDate: value.fromDate ? format(value.fromDate, dateFormat) : null,
        toDate: value.toDate ? format(value.toDate, dateFormat) : null,
      });
      // }

      popupState.close();
    }
  }, [onApply, popupState, value.fromDate, value.toDate, dateFormat]);

  const onCleanHandler = useCallback(() => {
    setRangeDate(null, null);
  }, [setRangeDate]);

  return (
    <>
      {cloneElement(button as any, {
        ...bindTrigger(popupState),
      })}
      <Popover
        {...bindPopover(popupState)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        onClose={(_, reason) => {
          if (reason === 'backdropClick') {
            setRangeDate(
              defaultValue?.fromDate ?? null,
              defaultValue?.toDate ?? null
            );
            popupState.close();
          }
        }}
        PaperProps={{ sx: { p: 0 } }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <LocalizationProvider
          adapterLocale={ptBrLocale}
          dateAdapter={AdapterDateFns}
        >
          <Stack
            direction={{ xs: 'column', sm: 'row' }}
            divider={
              <Divider
                flexItem
                orientation="vertical"
              />
            }
            justifyContent="space-around"
          >
            <StaticDatePicker
              displayStaticWrapperAs="desktop"
              maxDate={value.toDate}
              onChange={(newValue) => {
                setFromDate(newValue);
              }}
              openTo={calendarOpenTo}
              renderInput={(params) => <TextField {...params} />}
              value={value.fromDate}
              views={calendarViews}
            />
            <StaticDatePicker
              displayStaticWrapperAs="desktop"
              minDate={value.fromDate}
              onChange={(newValue) => {
                setToDate(newValue);
              }}
              openTo={calendarOpenTo}
              renderInput={(params) => <TextField {...params} />}
              value={value.toDate}
              views={calendarViews}
            />
          </Stack>
          <Stack
            direction={{ xs: 'column', md: 'row' }}
            spacing={1}
            sx={{ bgcolor: 'grey.100', p: 2 }}
          >
            <RadioGroup
              onChange={setDateByPreset}
              row
              sx={{ flexGrow: 1 }}
              value={currentPresetSelected ?? null}
            >
              {Object.entries(suggestions).map(([key, { label }]) => (
                <FormControlLabel
                  control={<Radio />}
                  key={key}
                  label={label}
                  value={key}
                />
              ))}
            </RadioGroup>
            <Stack
              direction="row"
              justifyContent="flex-end"
              spacing={1}
            >
              <Button
                disabled={!value.fromDate || !value.toDate}
                onClick={onCleanHandler}
                variant="text"
              >
                {setDefaultValueOnReset ? 'Definir padrão' : 'Limpar'}
              </Button>
              <Button
                color="tertiary"
                disabled={!validateApply}
                onClick={onApplyHandler}
                size="small"
                variant="contained"
              >
                Ok
              </Button>
            </Stack>
          </Stack>
        </LocalizationProvider>
      </Popover>
    </>
  );
};

export default DatePeriodFilter;
