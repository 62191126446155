import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Link, useSearchParams } from 'react-router-dom';

import { Add, Delete, PhoneIphone } from '@mui/icons-material';
import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Chip,
  Collapse,
  IconButton,
  Pagination,
  Paper,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material';
import { contrastColor } from 'contrast-color';
import { format } from 'date-fns';
import stringToColor from 'string-to-color';

import ContentNavbarFilter from '../../../../components/filters/content-navbar-filter';
import Content from '../../../../components/layouts/content';
import Loading from '../../../../components/loading';
import { useDrawer } from '../../../../contexts/drawer';
import { Slot } from '../../../../contexts/slot';
import resources from '../../../../resources';
import { IContent } from '../../../../resources/content';
import { IPagination } from '../../../../resources/pagination';
import ContentPreview from '../../components/content-preview';
import { getVideoCode } from '../../helpers/youtube';

interface IContentItem {
  content: IContent;
  onDelete?: React.MouseEventHandler<HTMLButtonElement>;
}

const ContentItem: React.FC<IContentItem> = ({ content, onDelete }) => {
  const drawer = useDrawer();
  const footer = (
    <Stack
      alignItems="center"
      direction="row"
      justifyContent="space-between"
    >
      <Typography
        sx={{ m: 2 }}
        variant="caption"
      >
        Data de publicação em{' '}
        {format(new Date(content.publishDate), 'dd/MM/yyyy')}
      </Typography>
      <CardActions>
        <Button
          component={Link}
          size="small"
          to={`./${content.id}`}
        >
          Editar
        </Button>
        <Tooltip title="Visualizar">
          <IconButton
            onClick={() => {
              drawer.open({
                element: (
                  <Stack spacing={2}>
                    <Typography
                      sx={{
                        fontSize: 25,
                        textAlign: 'center',
                        fontWeight: 'bold',
                      }}
                    >
                      Visualização
                    </Typography>
                    <ContentPreview values={content} />
                    <Button
                      onClick={() => drawer.closeAll()}
                      variant="contained"
                    >
                      Fechar
                    </Button>
                  </Stack>
                ),
                options: {
                  PaperProps: {
                    elevation: 0,
                    sx: {
                      alignItems: 'center',
                      width: 350,
                      height: 1,
                      p: 3,
                    },
                  },
                },
              });
            }}
            size="small"
          >
            <PhoneIphone />
          </IconButton>
        </Tooltip>
        <Tooltip title="Excluir">
          <IconButton
            onClick={onDelete}
            size="small"
          >
            <Delete />
          </IconButton>
        </Tooltip>
      </CardActions>
    </Stack>
  );

  return (
    <Card sx={{ display: 'flex', flexDirection: { xs: 'column', sm: 'row' } }}>
      <>
        {content.type === 'youtube' && (
          <>
            <CardMedia
              component="img"
              image={`https://img.youtube.com/vi/${getVideoCode(
                content.youtube?.videoUrl
              )}/0.jpg`}
              sx={{
                flexBasis: 1,
                objectFit: 'cover',
                width: { xs: '100%', md: '40%' },
              }}
            />
            <Stack
              spacing={2}
              sx={{ flexGrow: 1 }}
            >
              <CardContent sx={{ flexGrow: 1 }}>
                <Typography
                  component="div"
                  gutterBottom
                  sx={{ fontSize: 20, fontWeight: 'bold' }}
                >
                  {content.youtube?.title ?? 'Conteúdo de Video'}
                </Typography>
                <Stack
                  direction="row"
                  spacing={1}
                >
                  {content.tags.map((tag, key) => (
                    <Chip
                      key={key}
                      label={tag}
                      size="small"
                      sx={{
                        bgcolor: stringToColor(tag),
                        color: contrastColor({ bgColor: stringToColor(tag) }),
                        fontSize: 10,
                      }}
                    />
                  ))}
                </Stack>
              </CardContent>
              {footer}
            </Stack>
          </>
        )}
        {content.type === 'image' && (
          <>
            <CardMedia
              component="img"
              image={content.image.imageUrl}
              sx={{
                flexBasis: 1,
                objectFit: 'cover',
                width: { xs: '100%', md: '40%' },
              }}
            />
            <Stack
              spacing={2}
              sx={{ flexGrow: 1 }}
            >
              <CardContent sx={{ flexGrow: 1 }}>
                <Typography
                  component="div"
                  gutterBottom
                  sx={{ fontSize: 20, fontWeight: 'bold' }}
                >
                  {content.image?.title ?? 'Conteúdo de Imagem'}
                </Typography>
                <Stack
                  direction="row"
                  spacing={1}
                >
                  {content.tags.map((tag, key) => (
                    <Chip
                      key={key}
                      label={tag}
                      size="small"
                      sx={{
                        bgcolor: stringToColor(tag),
                        color: contrastColor({ bgColor: stringToColor(tag) }),
                        fontSize: 10,
                      }}
                    />
                  ))}
                </Stack>
              </CardContent>
              {footer}
            </Stack>
          </>
        )}
        {content.type === 'feed' && (
          <>
            <CardMedia
              alt="green iguana"
              component="img"
              image={content.feed.imageUrl}
              sx={{
                flexBasis: 1,
                objectFit: 'cover',
                width: { xs: '100%', sm: '40%' },
              }}
            />
            <Stack sx={{ flexGrow: 1 }}>
              <CardContent sx={{ flexGrow: 1 }}>
                <Typography
                  component="div"
                  sx={{ fontSize: 20, fontWeight: 'bold' }}
                >
                  {content.feed.title}
                </Typography>
                {/* <Typography
                  component="div"
                  gutterBottom
                  variant="caption"
                >
                  {content.feed.subtitle}
                </Typography> */}
                <Stack
                  direction="row"
                  spacing={1}
                >
                  {content.tags.map((tag, key) => (
                    <Chip
                      key={key}
                      label={tag}
                      size="small"
                      sx={{
                        bgcolor: stringToColor(tag),
                        color: contrastColor({ bgColor: stringToColor(tag) }),
                        fontSize: 10,
                      }}
                    />
                  ))}
                </Stack>
              </CardContent>
              {footer}
            </Stack>
          </>
        )}
      </>
    </Card>
  );
};

const ContentList: React.FC = () => {
  const [searchParams] = useSearchParams();
  const [loading, setLoading] = useState(false);
  const [contents, setContents] = useState<IPagination<IContent>>({
    data: [],
    meta: {
      limit: 0,
      page: 0,
      pages: 0,
      records: 0,
    },
  });

  const fetchContents = useCallback(async () => {
    const params: Record<string, any> = {
      ...(searchParams.has('tags')
        ? { tags: searchParams.getAll('tags').join(',') }
        : { tags: null }),
      ...(searchParams.has('fromDate')
        ? { startPublishDate: searchParams.get('fromDate') }
        : {}),
      ...(searchParams.has('toDate')
        ? { endPublishDate: searchParams.get('toDate') }
        : {}),
      ...(searchParams.has('centers')
        ? { codeCenter: searchParams.getAll('centers').join(',') }
        : {}),
    };
    const response = await resources.use('content').getAll(params);

    if (response.status === 'OK' && response.payload) {
      setContents((old) => ({
        ...old,
        ...response.payload,
      }));
    }
  }, [searchParams]);

  const onDelete = useCallback(
    async (content: IContent) => {
      const response = await resources.use('content').deleteById(content.id);

      if (response.status === 'OK') fetchContents();
    },
    [fetchContents]
  );

  const contentsOrdened = useMemo(() => contents.data ?? [], [contents.data]);

  useEffect(() => {
    setLoading(true);
    Promise.all([fetchContents()]).finally(() => {
      setLoading(false);
    });
  }, [fetchContents]);

  return (
    <>
      <Slot name="navbar">
        <ContentNavbarFilter />
      </Slot>
      <Content
        header={
          <Button
            component={Link}
            startIcon={<Add />}
            to="./create"
            variant="contained"
          >
            Criar novo
          </Button>
        }
        title="Central de Conteúdos"
      >
        <Stack
          direction="column"
          spacing={1}
        >
          <Loading loading={loading} />
          {(contents?.data ?? []).length === 0 && (
            <Paper sx={{ p: 3 }}>
              <Typography variant="caption">
                Nenhum conteúdo disponível
              </Typography>
            </Paper>
          )}
          {(contentsOrdened ?? []).map((content, key) => (
            <ContentItem
              content={content}
              key={key}
              onDelete={() => onDelete(content)}
            />
          ))}
          <Collapse in={contents?.meta?.pages > 1}>
            <Stack
              direction="row"
              justifyContent="flex-end"
              pt={4}
            >
              <Pagination
                count={contents?.meta?.pages}
                sx={{ justifySelf: 'center' }}
              />
            </Stack>
          </Collapse>
        </Stack>
      </Content>
    </>
  );
};
export default ContentList;
